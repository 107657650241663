<template>
  <div class="layout generic-error-layout">
    There is no layout defined for the site or page
  </div>
</template>

<script lang="ts">
import { Component } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import GenericPage from '@fwk-client/components/mixins/GenericPage.vue';

@Component({
  components: { }
})
export default class GenericError extends mixins(GenericPage) {
  
  constructor() {
    super();
  }

  mounted() {
    // We trigger the scroll when the layout is mounted coming from an other layout.
    // This event is catched by the generic triggerScroll event listener until the scrollBehavior method is called.
    if(process.env.CONSOLE == "LOG") {
      console.log("GENERIC ERROR LAYOUT - MOUNTED - WE TRIGGER SCROLL");
    }
    this.$root.$emit('triggerScroll');
  }

  /*
   *  Transition afterLeave event
   *  We emit triggerScroll event to be catched by Router.scrollBehavior when there is a transition to a new route in the same Layout
   */
  afterLeave () {
    if(process.env.CONSOLE == "LOG") {
      console.log("GENERIC ERROR LAYOUT - AFTER LEAVE - WE TRIGGER SCROLL");
    }
    this.$root.$emit('triggerScroll');
  }

}
</script>